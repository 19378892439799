#reviewPageMainBox {
  padding: 0px;
  min-height: 60vh;
}

#brandlogo {
  width: 220px;
}

.reviewPagecreateIncident {
  border-radius: 3px !important;
  background-color: #fff;
  margin-top: 12px;
  border: 1px solid;
  /* color: #e27a13 !important; */
  font-size: 14px !important;
  padding: 6px 12px;
}

.reviewPageTitle {
  padding-left: 1rem;
}

.reviewPageIncidentList {
  width: 100%;
  text-align: center;
}

.reviewPageIncidentList>thead>tr>th {
  border-bottom: 2px solid hsl(0, 0%, 87%);
  font-weight: bold;
  font-size: small;
}

.reviewPagedataRows {
  border-bottom: 2px solid #ddd;
  padding: 10px;
  background-color: #f4f4f4;
  font-size: 14px;
  color: #6f7172;
  text-align: center;

}

.nextaction {
  color: #e27a13;
}

.reviewPageIncidentBody>tr>td {
  font-size: 14px;
  font-weight: bold !important;
  inline-size: 90px !important;
  overflow-wrap: break-word !important;
  padding: 8px !important;
}

.reviewPageIncidentList:hover {
  cursor: pointer;
  /* Cursor style on hover */

}

.reviewPageIncidentHeader>tr>th {
  color: #b4b4b4;
  background-color: #fff;
  padding: 0.5rem;
}

.reviewPageIncidentList {
  line-height: 1.42857143;
  color: #6f7172;
}

#searchBox {
  padding: 10px;
}

.footerItems {
  list-style: none;
}

.bottomText {
  font-weight: bolder;
  font-size: x-large;
  padding: 2rem;
  background-color: #3a3a3a;
}

.clr {
  text-decoration: none;
  color: grey;
}

.clr:hover {
  text-decoration: none;
  color: grey;
}

.heading1 {
  color: #6f7172;
  font-size: 14px;
  padding: 0px;
  margin-bottom: 0px;
}

.reviewincidentTitleSection {
  height: 64px;
  background-color: #f4f4f4;
}


/* ////////// */

.bg_image {
  position: relative;
}

.container-fluid {
  position: relative;
}

.reviewPageitemOcrText {
  position: relative;
  z-index: 1;
  text-align: center;
  color: grey;
  font-weight: bold;
}

.itemBackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 108%;
  height: 90%;
  z-index: 0;
  background-image: url('/src/Assets/plate_outline_light_290x80.png');
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  margin-top: 3.5px;
  margin-bottom: 0px;
  border-bottom: 2px solid lightgrey;
  border-radius: 7px;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.reviewPageNoDataText {
  color: #6f7172;
  font-size: 18px;
  font-weight: bold;
  text-align: center;

}

.reviewPagedataRows:hover>td {
  background-color: white !important;
  font-weight: bolder !important;
}
