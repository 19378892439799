.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f1f1f1;
}

.box {
  display: grid;
  place-items: center;
  height: 100px; /* Set the desired height of the container */
}

.card {
  border: none;
}

.card:hover{
  cursor: pointer;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #ececec;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card-text {
  color: #6f7172;
}

