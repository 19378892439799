
.OrderFrsCardTitleSection {
  height: 64px;
  background-color: #f4f4f4;
  border: none;
}

.orderFrsCardsContainerMaxWidth {
  max-width: 1170px;
  margin: 0 auto;
}

.OrderFrsCardPageBodyContent {
  padding: 0 15px;
}

.OrderFrsCardPageRow {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.OrderFrsCardPagecolSm12 {
  display: flex;
  justify-content: center;
}

.OrderFrsCardHeading {
  color: #6f7172;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}
.OrderFrsCardHeading:hover {
  color: #6f7172;
}

.orderFrsCardcustomImage {
  width: 300px; /* Set the desired width */
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3); /* Horizontal offset, vertical offset, blur radius, color */
  height: auto; /* Maintain aspect ratio */
}
#orderDescription {
  margin-top: 20px;
  font-size: 16px;
  color: #6f7172;
  line-height: 1.42857143;
}
#orderSiteContainer {
  margin-top: 20px;
  color: #6f7172;
  font-size: 14px;
  line-height: 1.42857143;
}
#orderSiteContainer label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.orderFrsPageBody {
  max-width: 1170px;
  margin: auto;
}
#orderFrsSiteId {
  width: 200%;
  height: 34px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
}
#orderQuantityContainer {
  margin-top: 20px;
  color: #6f7172;
  font-size: 14px;
  line-height: 1.42857143;
}
#orderQuantityContainer label {
  min-width: 150%;
  margin-bottom: 5px;
  font-weight: 700;
}

#orderQuantityContainer .quantitySecondLabel {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

#orderButton {
  width: 30%;
  padding: 6px 12px;
}
