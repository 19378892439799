/* EmailModal.css */
#printPDF-body{
  cursor: pointer !important;
}
.email-modal-header {
  margin: 2px;
}

.email-modal-title {
  font-size: 18px;
  font-weight: 700;
  color: #e27a13;
}

.email-modal-body {
  background-color: #f4f4f4;
  font-size: 14px;
  font-weight: 400;
  min-height: 100px;
}

.report-type-container {
  margin: 3px;
}

.email-label {
  margin-left: -40px;
  
}

.email-input-container {
  margin: 3px;
}

.email-cancel-button {
    background-color: #ffffff;
    padding: 12px;
  }

.email-send-button {
  background-color: #e27a13;
  color: #ffffff;
  padding: 12px;
}
#radio-detailed {
    margin-left: -47%;
    margin-right: 10px;
}
#radio-summary{
    margin-left: -39%;
    margin-right: 10px;
}