#home-page-box {
  color: #fff;
  /* background-color: #e27a13; */
  min-height: max-content;
  padding-bottom: 2rem;
}

.equal-width-container {
  width: 80%;
  /* Set the width to 80% or any other value you need */
  margin: 0 auto;
  /* Center the container */
}

#home-page-first-heading {
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding-left: 0px;
}

#home-page-second-heading {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 20px;
}

#home-page-widget-box {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

/* #home-screen-widget1 {
  max-width: 300px;
  max-height: 320px;
  border: none;
  margin-right: 10px;
  border-radius: 3%;
  padding: 8px;
  background-color: #aa5c0e;
  color: #fff;
} */
#home-screen-widget2 {
  max-width: 300px;
  max-height: 320px;
  padding: 5px;
  border: none;
  margin-left: 10px;
  border-radius: 3%;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* background-color: #aa5c0e; */
  min-width: 300px;
  min-height: 320px;
}

.widget2-headings {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  font-weight: bold;
  font-size: 18px;
  font-weight: bolder;
}

.heading-top-vehicles {
  /* border-bottom: 3px solid #bf854a; */
  padding-bottom: 10px;
}

.heading-visit {
  padding-bottom: 10px;
  padding-left: 20px;
  /* border-bottom: 3px solid #bf854a; */
}

#scrollbar-widget {
  margin-top: 0.5rem;
  max-height: 326px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #fff rgba(255, 255, 255, 0.3);
}

#scrollbar-widget::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

#scrollbar-widget::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color of the scrollbar track to transparent */
}

#scrollbar-widget::-webkit-scrollbar-thumb {
  background: transparent;
  /* Set the background color of the scrollbar thumb to transparent */
}

#scrollbar-widget::-webkit-scrollbar-thumb:hover {
  background: transparent;
  /* Set the background color of the scrollbar thumb on hover to transparent */
}

#bgimg {
  background-image: url("../Assets/plate_outline_light_290x80.png");
  background-size: 100% 100%;
  width: 70%;
  height: 45px;
  text-align: center;
  border-style: solid;
  /* border-color: #bf854a; Set the border color and width */
  padding-top: 10px;
}

#widget2-visit-content {
  width: 30px;
  /* Set the desired width of the circle */
  height: 30px;
  /* Set the desired height of the circle */
  border-radius: 50%;
  /* Make the element a circle by setting border-radius to 50% */
  /* border-color: #bf854a !important; */
  border-style: solid;
  /* Set the background color to white */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  padding: 20px;
}

/* last part stats css */
.stats-heading {
  text-align: center;
  min-height: 60px;
  font-size: 14px;
}

.stats-heading2 {
  text-align: center;
  min-height: 40px;
  font-size: 14px;
}

.stats-value {
  text-align: center;
  font-size: 32px !important;
}
.stats-value-recovered{
  text-align: center;
  font-size: 24px !important;
}

#stats-value-prevented-theft {
  text-align: center;
  /* Center the text horizontally */
  display: flex;
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Center the content vertically */
  border-bottom: 3px solid #fff;
  font-size: 32px;
  margin-left: 20px;
}

#stats-recovered-percent-graphic {
  width: 70px;
  height: 70px;
  margin-top: -12px;
}

#stats-recovered-percent-graphic-outline-circle {
  stroke: #aa5c0e;
}

#stats-recovered-percent-graphic-outline-circle {
  stroke-width: 3px;
  fill: none;
}

#stats-recovered-percent-graphic-arc-recovered {
  stroke-width: 3px;
  stroke: white;
  stroke-linecap: round;
  fill: none;
}

#stats-recovered-percent-graphic-text {
  fill: white;
  font-size: 24px;
}

#stats-num-open-incidents-badge {
  width: 65px;
  height: 65px;
  margin-top: 0px;
  line-height: 65px;
  border-radius: 50% !important;
  margin-left: auto;
  margin-right: auto;
  background-color: #3a3a3a;
}

#stats-review-now-button {
  width: 100%;
  height: 65px;
  margin-top: 0px;
  line-height: 52px;
  font-size: 20px;
  border-radius: 4px !important;
  background-color: #3a3a3a;
  color: #fff;
}

.body-home .body-content .cta-button {
  background-color: #3a3a3a;
}

/* hide download option for chart */
.apexcharts-toolbar {
  display: none !important;
}

/* tooltip custom desgin */
.apexcharts-tooltip-custom {
  color: black;
  font-size: 14px;
  background-color: #fff;
  padding: 2px;
  border: 1px solid black;
}

.license-number {
  position: relative;
  height: 40px;
  width: 65%;
  margin-right: 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 6px !important;
  line-height: 36px;
  font-size: 16px;
  font-weight: bold;
}

.hole {
  position: absolute;
  width: 16px;
  height: 8px;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px !important;
  top: 5px;
}

.hole-1 {
  left: 8px;
}

.hole-2 {
  right: 8px;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}


/* /////////// new cssss*/

.body-content {
  padding-left: 15px;
  padding-right: 15px;
  color: #fff;
}

.container-max-width {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

#home-welcome {
  text-align: center;
  font-size: 24px;
}

#home-welcome-title {
  padding-top: 20px;
  font-weight: bold;
}

#home-welcome-greeting {
  padding-top: 20px;
  padding-bottom: 20px;
}

#chart-heading-col {
  text-align: center;
  font-size: 16px;
}


#chevron-arrow-right {
  display: inline-block;
  border-right: 4px solid ;
  border-bottom: 4px solid #fff;
  width: 12px; height: 12px;
  transform: rotate(-45deg);
  cursor: pointer;
}