.returnToSiteBox{
  min-height: 450px;
  border: 1px solid #5d5f5f;
}

.top-heading-text {
  color: #6f7172;
  font-size: 14px;
  padding-bottom: 8px;
}


.send-payment-field-heading {
  color: #6f7172;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 1.42857143;
  margin-left: 10px;
}

#send-payment-email-input {
  max-height: 40px;
  width: 180%;
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #6f7172;
}
#send-payment-phoneNumber-input {
  max-height: 40px;
  width: 180%;
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #6f7172;
}



.TableEditMainBox {
  padding: 1rem;
}
.TableEditBox {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  background: transparent;
  color: #fff;
  border: 1px solid #5d5f5f;
  margin-bottom: 15px;
}
.TableEditButtonBox {
  display: flex;
  justify-content: flex-end;
}
.TableDiscardButton {
  text-decoration: none;
  color: #6f7172;
  font-size: 14px;
}

.TableSaveNoteButton {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid transparent;
  width: max-content;
}
