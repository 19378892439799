
.faqTitleBox {
  background-color: #f4f4f4;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.faqTitle {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.faqBox {
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.faqContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
}

.faqText {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0.5;

}
.faqRowHeadings {
  font-size: 22px;
}
.faqText:hover {
  opacity: 1;
  border: none;
}

.faqTextActive {
  opacity: 1;
  border: none;
}

.faqDetails {
  margin-top: 20px;
}

.faqDetails h2 {
  color: darkblue;
}

.faqText {
  display: flex;
  align-items: center;
  color: #6f7172;
}

.FaqCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* color: #e27a13; */
  font-weight: bold;
  margin-right: 10px;
  border: 1px solid;
}
.collapse-active {
  color: #e27a13;
}
#faqDataTextQuestion {
  color: #6f7172;
}
#faqDataTextQuestion:hover {
  color: #e27a13;
  cursor: pointer;
}
.faqDescription{
  color: #6f7172;
  cursor:text !important;
  padding-top: 0px !important;
}