
.search-page-box {
  min-height: 100vh;
  background-color: black;
}
.search-input-box {
  width: 80%;
  padding-left: 10%;
  margin-top: 2%;
}
.search-input-bar {
  background-color: black;
  color: #fff;
  font-size: 30px;
  border: none;
  height: 40px;
}
.input-active,
.input-filled {
  background-color: black;
  height: 40px;
  width: 35%;
  font-size: 30px;
  /* border: 2px solid black; */
  border-radius: 0;
  padding: 8px;
  color: white;
}

.input-active:focus{
  border: 2px solid black;
  outline: black;
}

.search-no-input-text {
  width: 80%;
  padding-left: 10%;
  margin-top: 2%;
  color: #fff;
}
.search-btns-text {
  padding-top: 10px;
  width: 80%;
  padding-left: 10%;
}
.search-map-view-box {
  max-width: 1140px;
  margin-left: 10%;
  margin-right: auto;
}
.search-list-view-box {
  max-width: 1170px;
  /* margin-left: 10%; */
  margin: auto;
}

.info-window-content {
  width: 300px;
  height: 300px;
}
.info-window-image {
  width: 100%;
  height: auto;
}
.search-no-output-text {
  width: 80%;
  padding-left: 10%;
  color: red;
}
.reviewPageIncidentList {
  width: 100%;
  text-align: center;
  background-color: #3a3a3a;
}
.reviewPageIncidentList > thead > tr > th {
  border-bottom: 2px solid hsl(0, 0%, 87%);
  font-weight: 300;
  background-color: grey;

  font-size: large;
}

.reviewPagedataRows {
  border-bottom: 2px solid #ddd;
  padding: 10px;
  background-color: #3a3a3a;
  font-size: 14px;
  color:  white;
  text-align: center;

}

.reviewPageIncidentBody > tr > td {
  font-size: small;
  font-weight: 600;
}

.info-window-text{
  margin-top: 0.5rem !important;
  font-weight: 900 !important;
  color: #d1ac1d !important;
}

.map-list{
  margin-bottom: 2rem;
}

.map-list:hover{
  cursor: pointer;
}

.active-map{
  color: #e27a13;
  border-bottom: 1px solid #e27a13;
}

.map{
  padding: 2rem;
}

.list{
  padding: 2rem;
}