/* Common styles for elements within .TroubleshootingPageTitleBox and .TroubleshootingPageContainer */

.TroubleshootingPageTitle,
.TroubleshootingPageBox,
.TroubleshootingPageContainer {
  max-width: 900px;
  margin: 0 auto;
}

/* .TroubleshootingPageTitleBox styles */
.TroubleshootingPageTitleBox {
  background-color: #f4f4f4;
  padding: 10px 0;
}

/* .TroubleshootingPageCircle styles */
.TroubleshootingPageCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #e27a13;
  font-weight: bold;
  margin-right: 10px;
  border: 1px solid;
}

/* .TroubleshootingPageRowHeadings styles */
.TroubleshootingPageRowHeadings {
  font-size: 22px;
  color: #e27a13;
  font-weight: normal;
}

.TroubleshootingPageRowHeadings:hover {
cursor: pointer;
}

/* .TroubleshootingPageContainer styles */
.TroubleshootingPageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
}


.TroubleshootingPageDataContainer {
  margin-top: 2rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.collapse-active {
  color: #e27a13;
}
.TroubleshootingPageDataTextQuestion {
  color: #6f7172;
}
.TroubleshootingPageDataTextQuestion:hover{
  cursor: pointer;
}

.TroubleshootingPageDescription {
  color: #6f7172;
  cursor: text !important;
}
.TroubleshootingPageSubHeadings {
  font-size: 14px;
  font-weight: bold;
  color: #6f7172;
}
.TroubleshootingPageDataBox {
  border: 1px solid #ccc;
  width: 900px;
  height: min-content;
  padding: 8px;
  font-size: 14px;
  color: #6f7172;
}
.TroubleshootingPageSubData{
  padding-top: 8px;
  font-size: 14px;
  color: #6f7172;
}
.TroubleshootingPageImage{
  margin-left: 25%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.TroubleshootingPageCheckButton{
  font-size: 14px;
  color: #6f7172;
  width: 100px;
  margin-left: -8px;
}
.TroubleshootingPageRadioOptionsButton{
  padding-left: 10px;
}
.ipadNotShowingText {
  font-size: 18px;
  margin-right: 1rem; /* You can adjust the margin as needed */
}
.questionCircleIcon {
  font-size: 20px;
}
.TroubleshootingPageHeading:hover{
cursor: pointer;
}