/* late-payment.css */

.latepayment-container {
  min-height: 420px;
  border: 1px solid #6f7172;
  padding: 15px;
}
.late-payment-field-heading {
  color: #6f7172;
  padding-bottom: 5px;
  font-size: 14px;
  margin-left: 5%;
  line-height: 1.42857143;
}


.calender-input-box{
    margin-left: 15px;
    width: max-content;
}
.rdtPicker{
  background-color: #fff !important;
  color: black;
}

#latepayment-date-input {
  max-height: 40px;
  width: 170%;
  padding: 0;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #6f7172;
  padding-left: 10%;
  margin-bottom: 10%;
  font-weight: normal;
}
/* Add this CSS to make the calendar icon white */
.calendar-input::-webkit-calendar-picker-indicator {
  background-color: #262626; /* Change calendar icon background color */
  color: #fff; /* Change icon color to white */
  font-size: 24px;
  border: 1px solid #6f7172;
  padding: 5px;
  border-radius: 3px; /* Optional: Add border-radius for better appearance */
}

.calendar-input::-webkit-calendar-picker-indicator:hover {
  background-color: #262626; /* Maintain same background color on hover */
  color: #fff; /* Maintain same icon color on hover */
  font-size: 24px;

}


#latepayment-penaltyAmount-input {
  max-height: 40px;
  width: 170%;
  color: #fff;
  background-color: transparent;
  border: 1px solid #6f7172;
  font-size: 14px;
}
#latepayment-referenceNumber-input {
  max-height: 40px;
  width: 180%;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  margin-left: 5%;
  border: 1px solid #6f7172 !important;
}


#late-payment-confirm-btn{
  background-color: #e27a13;
  border: none;
  padding: 8px 12px;
  color: #fff;
  width: 100%;
}
#late-payment-confirm-btn {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid transparent;
  width: max-content;
  margin-left: 8%;
}