.ManageEmployeesTitleSection {
  height: 64px;
  background-color: #f4f4f4;
  border: none;
}

.managePageContainerMaxWidth {
  max-width: 1170px;
  margin: 0 auto;
}

.managePageBodyContent {
  padding: 0 15px;
}

.managePageRow {
  max-width: 1170px;
margin: auto;
}

.managePagecolSm12 {
  display: flex;
  justify-content: center;
}

.manageEmployeesHeading {
  color: #6f7172;
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}

.manageEmployeesHeading:hover {
  color: #6f7172;
}

.positiveSymbol {
  font-size: x-large !important;
}

.employeesListTableContainer {
  max-width: 1170px;
  margin: auto;
}

.employeesListTableContainerHeading {
  text-align: left;
  color: #b4b4b4;
  font-weight: normal;
  padding: 8px;
}

#employeesAddButton {
  padding: 12px 0px !important;
}

.employeesListTableContainerBody {
  background-color: #f4f4f4;
}

.employeesListRowData {
  color: #6f7172;
  font-weight: normal;
  text-align: left;
}

.employeesListRow {
  transition: background-color 0.3s ease;
}

.employeesListRow:hover {
  background-color: #fff;
  /* Set your desired background color */
  cursor: pointer;
}

.manageEmployeeEditIcon {
  opacity: 0;
  /* Initial opacity */
  transition: opacity 0.3s ease;
}

.activeEditIcon {
  opacity: 1;
}

.employeesListRow:hover .manageEmployeeEditIcon {
  opacity: 1;
}

#employeeCount {
  padding: 12px 0px;
}

.employeeModalSubHeading {
  font-weight: bold;
}

.employeeModalCheckBox {
  margin-left: -18%;
  transform: scale(0.7);
  border-radius: 0px;
  font-size: 22px;
  padding: 5px;
}

.btnHover:hover {
  cursor: pointer;
}