
.send-payment-link-container {
  color: grey;
  border: 1px solid gray;
  min-width: 100px;
  min-height: 420px;
  padding: 15px;
}
.latepayment-input-box{
  min-height: 400px !important;
}
.send-payment-field-heading {
  color: #6f7172;
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 1.42857143;
  margin-left: 10px;
}

#send-payment-email-input {
  max-height: 40px;
  width: 180%;
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #6f7172;
}
#send-payment-phoneNumber-input {
  max-height: 40px;
  width: 180%;
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #6f7172;
}
#late-payment-confirm-btn{
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid transparent;
  width: max-content;
}