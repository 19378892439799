.Button {
  width: 100%;
  padding: 20px;
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  background-color: #e27a13;
  cursor: pointer;
  margin-top: 3px;
  border: none;
}
.CloseButton {
  background-color: #262626;
  color: #6f7172;
  border: 1px solid #383939;
  border-radius: 3px !important;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
}
.Title {
  line-height: 24px;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 350;
  color: rgb(111, 113, 114);
}
.Title2 {
  line-height: 24px;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 350;
  color: rgb(111, 113, 114);
}
.TitleSection {
  height: 64px;
  background-color: #f4f4f4;
  border: none;
}
.PageMainBox {
  min-height: max-content;
  box-sizing: border-box;
  line-height: 1.42857143;
  width: 100%;
}
.PageMainBoxOther{
  min-height: 60vh;
  box-sizing: border-box;
  line-height: 1.42857143;
  width: 100%;
}
.brandColorText {
  color: #e27a13;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0px;
}
.incidentBox1 {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  min-height: fit-content;
  border: 1px solid #6f7172;
  cursor: pointer;
  margin-bottom: 1rem;
  min-height: 490px;
}

.incidentBox2 {
  min-height: 450px;
  /* max-width: 510px; */
  border: 1px solid black;
  background-color: black;
  margin-bottom: 1rem;
}
.waiveFeesBox {
  min-height: 450px;
  /* max-width: 510px; */
  border: 1px solid black;
  background-color: #6f7172;
  margin-bottom: 1rem;
}
.incidentReturnToSiteBox {
  min-height: 450px;
  /* max-width: 510px; */
  border: 1px solid #6f7172;
}
.incidentShowVideoBox {
  height: 490px;
  /* max-width: 510px; */
  border: 1px solid black;
  background-color: black;
  margin-bottom: 1rem;
}
.incidentUploadVideoBox {
  height: 490px;
  /* max-width: 510px; */
  border: 1px solid #6f7172;
  margin-bottom: 1rem;
}
.customerBtn {
  border: 1px solid #6f7172;
  padding: 4px;
}
.selectedButton {
  background-color: #6f7172;
  color: #fff;
}
.recordsBox {
  min-height: 420px;
  border: 1px solid #6f7172;
  padding: 15px;
}
.evidenceImageBox{
  height: 490px;
  /* max-width: 510px; */
  border: 1px solid #6f7172;
  margin-bottom: 1rem;
}
.customTextarea:focus {
  border-color: transparent;
  outline: none; /* Optionally remove the default focus outline */
}