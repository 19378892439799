#review-incident-container {
    max-width: 1170px;
    margin: auto;
    font-size: 14px;
}

.review-incident-heading-container {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
}

.review-incident-heading-container .review-incident-heading {
    flex: 1;
    /* Ensures equal width for all items */
    /* max-width: 167.14px; */
    color: #b4b4b4;
    padding: 8px;
    text-align: center;
}

.review-incident-values-container .review-incident-values {
    flex: 1;
    /* Ensures equal width for all items */
    /* max-width: 167.14px; */
    padding: 8px;
    text-align: center;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Ensures text is centered if it wraps to multiple lines */
    word-break: break-word;
}

.review-incident-values-container {
    display: flex;
    justify-content: space-around;
    background-color: #f4f4f4;
    color: #6F7172;
    padding: 8px;
    vertical-align: middle;
    border-top: 1px solid #ddd;
    vertical-align: middle;
    max-height: 60px !important;
    cursor: pointer;

}

.review-incident-values-container:hover {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    color: #6F7172;
    padding: 8px;
    vertical-align: middle;
    border-top: 1px solid #ddd;
    vertical-align: middle;
    max-height: 60px !important;
    cursor: pointer;
}

.ocr-background {
    background-image: url('/src/Assets/plate_outline_light_290x80.png');
    /* Replace with your image path */
    background-size: contain;
    /* Ensures the image is contained within the div */
    background-repeat: no-repeat;
    /* Prevents the image from repeating */
    background-position: center;
    /* Centers the image within the div */
    background-origin: padding-box;
    /* Ensures the background image starts at the padding */
}

.wrapped-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 100%;
    /* Adjust as needed */
}
.css-194jcui{
    max-height: 18rem;
}