/* .feedbackmodal{
    font-weight: 800;
}

.feedbackmodal:hover{
    cursor: pointer;
}
.text-bold{
    font-weight: bolder;
}

. */
#feedback-modal{
    min-width: max-content;
}

/* FeedbackModal.css */
.modal-header .btn-close {
    /* Custom styles for the .btn-close button */
    font-size: 10px; /* Example: Increase the font size */
  }

  #feedback-modal-body{
    background-color: #f4f4f4 !important;
  }
  .feedback-input-form {
    border: none !important;
    background-color: transparent !important;
    outline: none !important; /* Remove the default outline */
  }
  
  .feedback-input-form:hover,
  .feedback-input-form:focus,
  .feedback-input-form:active {
    border: none !important;
    background-color: transparent !important;
    outline: none !important; /* Ensure outline is removed on focus */
    box-shadow: none !important; /* Remove any potential box-shadow */
  }
  