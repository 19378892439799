/* Styles for the main video container */

.view-video-box {
  min-height: 100vh;
}

/* Styles for the video row */
.view-video-row {
  background-color: #f4f4f4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

/* Styles for the video column */
.view-video-col {
  max-width: 1170px;
  margin: 0 auto; /* Center align the column */
  max-height: 64px;
}

/* Styles for the video title */
.view-video-title {
  line-height: 24px;
  font-weight: 100;
  color: #6f7172;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Styles for the video body */
.view-video-body {
  max-width: 1150px;
  height: 655px;
  margin: 0 auto; /* Center align the body */
  /* border: 1px solid #d3d3d3; */
  /* background-color: #f4f4f4; */
}

/* Styles for the video element */
.view-video-body video {
  width: 100%;
  padding: 5px;
  height: 100%;
}
