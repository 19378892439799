.notesBox {
  min-height: 420px;
  border: 1px solid #6f7172;
  padding: 15px;
}
.notesTopBox th {
  color: #6f7172;
  border: none;
  font-weight: normal;
  font-size: 14px;
  padding-top: 15px;
  text-align: left;
  padding: 8px;
}
.notesTopBox td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: none;
  border-bottom: 1px solid #5d5f5f;
  font-weight: normal;
  font-size: 14px;
}
/* css for showing notes more details */

.notesTable {
  width: 100%;
}
.notesTable td,
.notesTable th {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}
.notesTable th {
  color: #6f7172;
  border-bottom: 1px solid #5d5f5f;
  font-weight: normal;
}
.notesTable td {
  border-bottom: 1px solid #5d5f5f;
  font-weight: 400;
  font-size: 12px;
}
.notesBackBtn {
  color: #e27a13 !important;
  font-size: 14px;
  font-weight: bold;
}
.notesBackBtn:hover
{
  cursor: pointer;
  text-decoration: underline;
}

.backArrowIcon{
  font-size: 18px;
  font-weight: bolder;
}

.notesFullDetails {
  padding: 10px 10px;
  font-size: 14px;
}
.notesField {
  padding-left: 20px;
}
.notesHeading {
  padding: 0px 10px;
}
.notesFullDetailsHeading {
  color: #6f7172;
  border: none;
  font-weight: normal;
  font-size: 14px;
  /* padding-left:20px; */
}
.notesRows {
  color: #fff;
  font-size: 14px !important;
}

.notesTableEditBox {
  width: 100%;
  min-height: 350px;
  background: transparent;
  color: #fff;
  border: 1px solid #5d5f5f;
    outline: #5d5f5f;
  margin-bottom: 15px;
}
.notesTableEditButtonBox {
  display: flex;
  justify-content: flex-end;
}

.NoteBtn {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid transparent;
  width: max-content;
}
.noteCancelbtn{
  margin-right: 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: #6f7172;
  padding: 10px 20px;
  border: 1px solid transparent;
  width: max-content;
}

.notesTableSaveNoteButton{
margin-left: 1rem;
background-color: #e27a13;
border: none;
color: #fff;
}

.noteCancelbtn:hover{
  color: #6f7172;
  border-bottom: 1px solid #6f7172;
}