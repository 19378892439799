.Reports-Page {
  min-height: 60vh;
  max-height: fit-content;
  font-family: "proxima-nova-soft" , sans-serif !important;

}

.container-max-width-reports-page {
  max-width: 1170px;
  margin: auto;
}

.main-box {
  background-color: #f4f4f4;
  padding-bottom: 2px;
}

.second-box {
  width: 1170px;
  margin: 0 auto;
}

.title {
  flex: 0 0 auto;
  margin-bottom: 0;
  background-color: #f4f4f4;
}

.search-bar {
  flex: 0 0 auto;
  margin-top: 0px;
  background-color: #f4f4f4;
}

.chart-Box {
  width: 1160px;
  margin: 0 auto;
}

.report-heading-text {
  color: #6f7172 !important;
  font-size: 14px !important;
}

.chart-heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  color: #6f7172;
}


.div-item {
  position: relative;
  min-height: 1px;
  color: #6f7172;
  padding: 0px;
  font-size: 14px !important;
}

.div-item-second {
  margin-top: 20px;
  position: relative;
  min-height: 1px;
  color: #6f7172;
}

.dropdown-container button {
  max-width: 100% !important;
  /* Set your desired width */
  background-color: #fff;
}

.dropdown-container .dropdown-menu {
  width: 300px !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.report-title {
  line-height: 24px;
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 10px;
}

.report-filters-actions-heading {
  font-size: 18px;
  font-weight: bold;
  color: #b4b4b4;
  margin-bottom: 10px;
}

.action-btns {
  /* color: #e27a13 !important; */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.action-btns:hover {
  text-decoration: underline;
}

.disable-btns {
  font-size: 14px;
  font-weight: bold;
  color: #b4b4b4 !important;
  cursor: not-allowed;
}

.container-max-width-reports-page {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

/* Custom CSS to remove box-shadow for expanded accordion buttons */
.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-item {
  border-left: none !important;
  border-right: none !important;
}

.report-page-table-rows {
  padding: 15px;
  border-bottom: 1px solid #ececec;
}

.report-page-table-statistics {
  border-left: 1px solid #ececec;
}

.table-stats {
  font-size: 16px;
}

.apexcharts-legend.apx-legend-position-bottom {
  display: none !important;
}

/* css for Dot */

.summary-chart-legend-spot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px !important;
  margin-right: 8px;
}

/* setting the dropdown of sites */
.css-1r4vtzz,
.css-48ayfv {
  padding: 4px 8px !important;
}

.css-1jllj6i-control {
  border-color: none !important;
}

.dropdown-container {
  width: 160% !important;
}

.background-colour-chart-5 {
  background-color: #f7a123;
}

/* calendar css */
input[type="date"] {
  padding: 0px;
  font-size: 14px;
}

.report-date-field {
  border: 2px solid #ececec;
}

.report-date-field:hover {
  border: 4px solid #ececec;
}

input::-webkit-calendar-picker-indicator {
  font-size: 20px;
  padding: 10px;
  background-color: #f4f4f4;
}

input::-webkit-calendar-picker-indicator:hover {
  font-size: 20px;
  padding: 10px;
  background-color: #f4f4f4;
}

/* table data css to change font size */
.totals-label {
  text-align: right;
  font-weight: bold;
  color: #b4b4b4;
  font-size: 14px;
}

.totals-heading {
  font-weight: bold;
  color: #b4b4b4;
}

.totals-value {
  text-align: right;
  font-weight: bolder !important;
  font-size: 14px !important;
}

.report-bar-chart {
  min-height: 50px !important;
}

/* remove dropdown icon of accordion */
.accordion-button::after {
  display: none;
}

/* change accordion  bg colour to none */
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: inherit;
}

button.accordion-button:focus {
  box-shadow: inherit;
}

/* chart bg clr */
.advance-report-top-box {
  background-color: #f4f4f4;
}

.counts-heading {
  font-size: 18px !important;
  font-weight: bold;
  color: #6f7172;
}

/* chekbox button .advance-report */
/* From Uiverse.io by DaniloMGutavo */ 
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 5px;
  width: 6px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.custom-checkbox:checked ~ .checkmark {
  background-color: #2563EB;
  box-shadow: 0 3px 7px rgba(33, 150, 243, 0.3);
}

.custom-checkbox:checked ~ .checkmark:after {
  display: block;
}

@keyframes checkAnim {
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
}

.custom-checkbox:checked ~ .checkmark:after {
  animation: checkAnim 0.2s forwards;
}
