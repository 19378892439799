/* RotatingIcon.css */
.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.thick {
  font-weight: bold; /* Make the icon thicker */
}
