.detailsSubRow {
  min-height: 2.5rem;
}

.detailsLastRow {
  border-top: 1px solid #6f7172;
  min-height: fit-content;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.detailsColumnRight {
  padding: 10px 15px;
  border-right: 1px solid #6f7172;
  border-bottom: 1px solid #6f7172;
}

.detailsColumnLeft {
  border-bottom: 1px solid #6f7172;
  padding: 10px 15px;
}

.detailsMiddleColumn {
  padding: 10px 15px;
}

.incidentDetailBodyHeading {
  font-size: 14px;
  padding-bottom: 4px;
}


#incidentDetailBodyLicenceNumber {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 4px;

}

#incidentDetailPumpNumber {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 4px;

}

.incidentDetailValues {
  font-size: 14px;
  font-weight: normal;
}

.mutlipleDataShow {
  padding-right: 4px;
}

.inputTagStyle {
  max-height: 35px;
  font-size: 14px;
}

.reviewSubmitButton {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  /* background-color: #e27a13; */
  border: none;
  /* margin-top: 1rem; */
  padding: 6px 12px;
  width: 90%;
  height: 100%;
  padding: 20px;
}

.iconsContainer {
  display: flex;
  justify-content: space-around;
  background-color: #efefef;
  color: black;
  align-items: center;
  /* Vertically align the buttons */
}

.iconsAlignment {
  /* Add any other necessary styling */
  margin: 0 0;
  /* Adjust the margin as needed */
  background-color: #efefef;
  font-weight: 900;
}

.radioBtnLabelsDetailsPage {
  font-size: 14px;
  white-space: pre-wrap;
}

.incidentTermsConditionsText {
  font-size: 14px;
  padding: 10px 10px 10px 0;

  color: #ffffff !important;
  line-height: 1.42857143;
  white-space: pre-wrap;
}

.mutlipleDataShow {
  max-width: 550px;
  /* Adjust the value as needed */
  word-wrap: break-word;
}

.mutlipleDataShowAddress {
  max-width: 600px;
  /* Adjust the value as needed */
  word-wrap: break-word;
}

.radio:hover {
  cursor: pointer;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.iconsAlignmentFuelLeft,
.iconsAlignmentFuelRight {
  padding: 4px 10px;
  border: none;
}

/* new css */

/* yourStyles.module.css */
.gender-buttons {
  background-color: #262626;
  cursor: pointer;
  padding: 5px 10px;
  margin: 2px;
  display: inline-block;
  color: #fff;
  border: 1px solid #ccc;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.gender-buttons:hover,
.gender-buttons:focus,
.gender-buttons.active,
.gender-buttons.selected {
  color: #000;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
