.add-incident-page-main-box {
  padding: 0px;
}

.add-Incident-body {
  padding-top: 20px;
  background-color: #fff;
  color: #6f7172;
  font-size: 14px;
  font-size: 14px;
  line-height: 1.42857143;

  background-color: #fff;
}

.title-box {
  background-color: #f4f4f4;
}

.title-row {
  max-width: 10000px;
  margin-left: auto;
  margin-right: auto;
}

.highlight {
  color: red;
  border: 1px solid red;
}

.add-incident-heading {
  font-weight: bold;
  font-size: 14px;
  color: #6f7172;
  padding: 10px;
}

.col-3-right {
  margin-bottom: 20px;
}

.add-incident-heading-row {
  padding-bottom: 10px;
}

.add-incident-submit-btn {
  margin-top: 20px;
  background-color: #e27a13;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  padding: 10px 30px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: none;
}

.add-incident-firs-page-submit-btn {
  margin-top: 20px;
  background-color: #e27a13;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 65%;
  border: none;
}

.input-error {
  border: 1px solid red;
}

#add-incident-dropDown {
  font-size: 14px;
  width: 100%;
  border: 1px solid grey;
  /* Set the normal border color */
  background-color: #fff;
  padding: 4px;
  /* Adjust the padding for the select element */
  transition: border-color 0.3s;
  /* Add transition for a smoother effect */
  box-shadow: none;
  /* Remove drop shadow initially */
}

#add-incident-dropDown:hover {
  border-color: lightblue;
  /* Change the border color on hover */
}

#add-incident-dropDown:focus {
  border-color: lightblue;
  /* Change the border color on click */
  box-shadow: 0 0 5px lightblue;
  /* Add drop shadow on click */
}

#add-incident-dropDown option {
  padding: 10px;
  /* Set padding for the dropdown options */
}

.box-dropdown {
  height: 10px !important;
}

.css-pwxzbm {
  max-height: 16rem !important;
}


.custom-datetime-local {
  position: relative;
  display: inline-block;
}

.custom-datetime-local input[type="datetime-local"] {
  padding-right: 40px;
  /* Increased to accommodate the larger icon area */
}

.custom-datetime-local input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.custom-datetime-local .calendar-icon-wrapper {
  position: absolute;
  right: 0px;
  top: 45%;
  transform: translateY(-50%);
  width: 35px;
  /* Adjust as needed */
  height: 36px;
  /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  /* Light grey background */
  border: 1px solid #ccc;
  /* Light grey border */
  pointer-events: none;
  /* Allows clicks to pass through to the input */
}

.custom-datetime-local .calendar-icon {
  color: #6f7172;
  /* Adjust color as needed */
  font-size: 14px;
  /* Adjust size as needed */
}

.radio-group label,
.radio-group input[type="radio"],
.radio-group span {
  cursor: pointer;
}

/* custom select */
.select-container {
  position: relative;
  width: 100%;
}

.styled-select {
  width: 100%;
  padding: 8px 32px 8px 12px;
  font-size: 16px;
  color: #333333;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  appearance: none;
  cursor: pointer;
}

.styled-select:focus {
  outline: none;
  border-color: #1976d2;
}

.styled-select option {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  white-space: nowrap;
}

.styled-select option:active {
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  white-space: nowrap;
}

.select-label {
  position: absolute;
  left: 8px;
  top: -6px;
  padding: 0 4px;
  background-color: white;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.arrow-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.arrow-icon::after {
  content: '▼';
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
