/* LoginPage.css */

.page-container {
  height: 120vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #363636;
}

.content-container {
  margin-bottom: 20vh;
  width: 30vw;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 2%;
  border-radius: 2%;
}
.content-container-resetpassword {
  margin-bottom: 20vh;
  width: 30vw;
  min-width: 300px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  padding: 2%;
  border-radius: 2%;
}
.invalid-link-content-container {
  margin-bottom: 20vh;
  height: 60vh;
  width: 30vw;
  min-width: 300px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2%;
  border-radius: 2%;
}
.login-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0%;
}

.login-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* Shorthand for all padding sides */
}

/* Styles for .form-logo */
.form-logo {
  width: 50%;
  height: 50%;
  margin: 5% 0;
}
.forgotpassword-form-logo {
  width: 50%;
  height: 50%;
  margin: 10% 0;
}
/* Styles for .form-title  */
.form-title {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  color: #1e212a;
  line-height: 1.454;
  padding: 0.5rem 1rem; /* Adjust padding for top/bottom and left/right */
}

.forgotPassword-form-title h1 {
  font-size: 1.5rem !important;
  font-weight: 400;
  margin-bottom: 10%;
}
/* Styles for .form-subtitle */
.form-subtitle {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  padding: 0.5rem 0rem; /* Adjust padding for top/bottom and left/right */
  align-self: baseline;
}

.forgotPassword-form-subtitle {
  font-size: 0.875rem !important;
  font-weight: 400;
  margin-bottom: 8%;
  align-items: center;
}
/* Styles for .forgot-password-login */
.forgot-password-login {
  align-items: flex-start;
  margin: 0;
  color: #21358c;
  font-weight: 800;
}

/* Styles for .forgotPassword */
.forgotPassword {
  margin-top: 1px;
  cursor: pointer;
}

.forgotPassword:hover{
  text-decoration: underline;
}

/* Styles for .continueButton */
.continueButton {
  width: 100%;
  height: 7vh;
  font-size: 1rem;
  color: #fff;
  background-color: #21358c;
  border: none;
  border-radius: 5%;
}

.resetpasswordsuccess-continueButton {
  width: 100%;
  height: 7vh;
  font-size: 1rem;
  color: #fff;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 5%;
  border: 1px solid #ccc;
}
/* Styles for .form-errorMessage */
.form-errorMessage {
  font-size: 14px;
  color: red;
}

.support-text {
  font-size: 14px;
}
/* Styles for .inputError and .inputField */
.inputError,
.inputField {
  width: 24vw;
  margin-bottom: 0%;
}
.inputErrorPassword {
  border: 2px solid #d03c38;
}
/* Styles for .inputField when focused */
.inputField:focus {
  /* border: 1px solid #21358c;
  box-shadow: 1px 1px #21358c; */
}

.showPasswordLabel {
  width: 11rem;
  margin-left: -23px;
  margin-bottom: 0.5rem;
}
.showPasswordCheckbox {
  height: 1rem;
}
.back-button {
  color: #21358c;
  text-decoration: none;
  font-weight: 700;
}

/* styles.css */

.circle-icon {
  width: 80px; /* Adjust the width to make it bigger */
  height: 80px; /* Adjust the height to make it bigger */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff; /* Set the background color to white */
  color: green; /* Set the icon color to green */
  border: 2px solid green; /* Set the border color and width */
  margin: 20% 0 10% 0;
}
.round-box {
  width: 100px; /* Adjust the width to make it bigger */
  height: 100px; /* Adjust the height to make it bigger */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff; /* Set the background color to white */
  color: #d03c38; /* Set the icon color to red */
  border: 2px solid #d03c38; /* Set the border color and width */
  margin: 20% 0 10% 0;
}
.close-icon {
  font-size: 4rem !important;
  font-weight: 400 !important;
}
.invalid-text {
  font-size: 26px;
}

.invalid-paragraph {
  font-size: 14px;
  line-height: 1.7;
  word-wrap: break-word;
}
.round-box-Check-icon {
  width: 80px; /* Adjust the width to make it bigger */
  height: 80px; /* Adjust the height to make it bigger */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff; /* Set the background color to white */
  color: green; /* Set the icon color to green */
  border: 2px solid green; /* Set the border color and width */
  margin: 20% 0 10% 0;
}
.password-requirements {
  border: 1px #ccc;
  margin-left: -8rem;
  margin-top: 10px;
}
.Check-icon {
  font-size: 4rem !important;
  font-weight: 400 !important;
}
.invalid {
  color: #1e212a;
  font-size: 14px;
}
.valid {
  color: #13a688;
  font-size: 14px;
}
