.custom-nav-link {
  font-size: 14px !important;
  text-decoration: none;
}

.custom-nav-link:hover {
  color: #e27a13 !important;
}

.incident-detail-section {
  background-color: #262626;
  color: #fff;
}

.left-side-box {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  min-height: 550px;
  border: 1px solid gray;
}

.incident-detail-overview-heading {
  color: #6f7172;
  font-size: 14px;
  padding-top: 10px;
}

#incident-support-link {
  color: #a8a8a8;
  font-size: 14px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.incident-detail-overview-value {
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
}

.incident-type {
  font-size: 26px;
  padding: 0px;
}

.incident-brand-colour {
  color: #e27a13 !important;
}

.uploaded-video-box {
  width: 500px;
  height: 400px;
  margin-top: 10px;
}

.need-help-modal-heading {
  color: #e27a13 !important;
  font-size: 14px;
  font-weight: bold;
}

.btn-modal-close-feedback {
  width: 100%;
  font-weight: bold;
  margin: 0;
  border: none;
  height: 100%;
  padding: 12px;
}

.btn-modal-submit-feedback {
  width: 100%;
  font-weight: bold;
  margin: 0;
  border: none;
  color: #fff;
  height: 100%;
  background-color: #e27a13;
  padding: 12px;
}

.video-show-box {
  display: flex;
  flex-direction: column;
}


.needhelp:hover {
  text-decoration: underline;
}

.custum-file-upload {
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  /* border: 2px dashed #e8e8e8; */
  background-color: transparent;
  padding: 1.5rem;
  border-radius: 10px;
  /* box-shadow: 0px 48px 35px -48px #e8e8e8; */
}

.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon svg {
  height: 80px;
  /* fill: #e8e8e8; */
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
}

.custum-file-upload input {
  display: none;
}

.iconsContainer {
  display: flex;
  justify-content: space-around;
  background-color: #efefef;
  color: black;
  align-items: center;
  /* Vertically align the buttons */
}

.iconsAlignment {
  /* Add any other necessary styling */
  margin: 0 0;
  /* Adjust the margin as needed */
  background-color: #efefef;
  font-weight: 900;
  padding: 8px;
  border: 1px solid grey;
}

/* styles.css */

.video-show-box {
  width: 100%;
  max-height: 450px;
}

.video-element {
  width: 100%;
  min-height: 450px !important;
}

.video-text-container {
  width: 100%;
  min-height: 450px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-text {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
  width: 85%;
  font-size: 14px;
  font-weight: 400;
}



/* /////////////////////////new css for new better component */
.incident-details-container {
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  /* Center the container */
  gap: 10px;
  /* Adjust the gap between items if needed */
  justify-content: space-between;
  /* Space between items */
  flex-wrap: wrap;
  /* Allow wrapping on smaller screens */
  font-size: 14px;
}

.incidet-type-value {
  font-size: 24px !important;
}

.incident-details-item {
  padding: 12px 0px;
  /* Padding for items */
  box-sizing: border-box;
  /* Include padding and border in element's total width and height */
  flex: 1;
  /* All items grow equally */
  display: flex;
  flex-direction: column;
}

.incident-details-needhelp {
  float: right;
}

.incident-details-need-help {
  flex: 1;
  /* The last item grows twice as much as the others */
}

.incident-details-heading {
  color: #6f7172;
}

.incident-details-value {
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px ;
}

.incident-details-status {
  color: #e27a13 !important;
}

.incident-details-needhelp {
  cursor: pointer;
  color: #a8a8a8;
  float: right;
}

.incident-details-needhelp:hover {
  text-decoration: underline;
}

.custom-datetime-local .calendar-icon-wrapper {
  position: absolute;
  right: 0px;
  top: 48%;
  transform: translateY(-50%);
  width: 35px;
  /* Adjust as needed */
  height: 34px;
  /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  /* Light grey background */
  border: 1px solid #ccc;
  /* Light grey border */
  pointer-events: none;
  /* Allows clicks to pass through to the input */
}

/* chevron arrow css */

#chevron-arrow-left {
  margin-left: 6px;
  margin-right: 6px;
  display: inline-block;
  border-right: 4px solid #e27a13;
  border-bottom: 4px solid #e27a13;
  width: 12px; height: 12px;
  transform: rotate(-225deg);
  cursor: pointer;
 
}

#chevron-arrow-right {
  display: inline-block;
  border-right: 4px solid ;
  border-bottom: 4px solid #e27a13;
  width: 12px; height: 12px;
  transform: rotate(-45deg);
  cursor: pointer;
}

/* css for details headings */
#incident-review-box-data-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: row; /* Default to row for large and medium screens */
  justify-content: space-between;
  
}

/* Small screens (max-width 768px and below) */
@media (max-width: 768px) {
  #incident-review-box-data-container {
    flex-direction: column; /* Change to column for small screens */
  }
}

#heading-right-box{
  margin-left: 20px;
}


#heading-right-tab-box{
 
  font-size: 16px !important;
  width: 575px;
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to the next line */
  gap: 10px; /* Optional: Adds spacing between items */
}
#heading-left-tab-box{
  color: #6f7172;
  font-size: 16px !important;
  width: 575px;

  display: flex;
  flex-wrap: wrap; /* Allow wrapping to the next line */
  gap: 10px; /* Optional: Adds spacing between items */
}
.heading-left-box-item{
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  max-width: fit-content;
  margin: 0 2px 0 0; /* Set margin: 0 top, 2px right, 0 bottom, 0 left */
  padding: 10px 15px;
}
.heading-right-box-item{
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  max-width: fit-content;
  margin: 0 2px 0 0; /* Set margin: 0 top, 2px right, 0 bottom, 0 left */
  padding: 10px 15px;
}
.check.icon {
  position: absolute;
  margin-left: 3px;
  margin-top: 4px;
  width: 14px;
  height: 8px;
  font-weight:'bolder';
  border-bottom: solid 3px currentColor;
  border-left: solid 3px currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}


/* gender radio buttons css */ 
.mydict :focus {
  outline: 0;
  border-color: #2260ff;
  box-shadow: 0 0 0 4px #b5c9fc;
}

.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  justify-content: center;
}

.mydict input[type="radio"] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict input[type="radio"]:checked + span {
  box-shadow: 0 0 0 0.0625em #adadad; /* Border color */
  background-color: #666; /* Active background color */
  z-index: 1;
  color: #fff;
}

.mydict label span {
  display: block;
  cursor: pointer;
  background-color: #262626; /* Background color */
  padding: 0.375em .75em;
  position: relative;
  margin-left: .0625em;
  box-shadow: 0 0 0 0.0625em #b5bfd9;
  letter-spacing: .05em;
  color: #fff; /* Text color */
  text-align: center;
  transition: background-color .5s ease, color .5s ease, box-shadow .5s ease;
}

.mydict input[type="radio"]:focus + span {
  outline: 0;
  border-color: #adadad; /* Outline color */
  box-shadow: 0 0 0 0.0625em #adadad; /* Border color */
}

.mydict label:first-child span {
  border-radius: .375em 0 0 .375em;
}

.mydict label:last-child span {
  border-radius: 0 .375em .375em 0;
}


/* new cross tick button css */

.icon-button svg {
  width: 12px;
  height: 12px;
  color: #000;
}