/* TrainingVideos.css */

.trainging-videos-page-box {
  min-height: 60vh;
  box-sizing: border-box;
}

.training-videos-title-section {
  background-color: #f4f4f4;
}

.title-container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  max-height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-traing-videos {
  line-height: 24px;
  font-weight: 100;
  color: #6f7172;
  margin: 0;
}

.training-videos-body-section {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}
.trainging-videos-second-title {
  color: #6f7172;
  margin-top: 20px;
  margin-bottom: 10px;
}
.second-title-trainging-videos {
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  font-size: 24px;
}
.card-disabled{
  opacity: .5;
}
.card-link {
  text-decoration: none;
}
.card-box{
  border: 1px solid #d3d3d3;
}
.card-img-top{
  padding: 5px;
}

.training-title {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #ececec;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #e27a13;
}
.training-text{
  font-size: 14px;
  color: #6f7172;
}

.card-img-top:hover{
  background-color: #e27a13;
  opacity: 1;
  cursor: pointer;
}

.training-title:hover , .training-text:hover {
  cursor: pointer;
}